<template>
    <vx-card title="Canvas - Unloading">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="Receive">
                    <tab-receive></tab-receive>
                </vs-tab>
                <vs-tab label="Process">
                    <tab-check></tab-check>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabReceive from "./TabReceive.vue";
import TabCheck from "./TabCheck.vue"; // sekarang jadi tab putaway
import TabComplete from "./TabComplete.vue";
export default {
    components: {
        TabReceive,
        TabCheck, // sekarang jadi tab putaway
        TabComplete
    },
    data() {
        return {
            activeTab: "Receive",
            render: true,
            settingValue: "",
            tab: 0,
        }
    },
    mounted() {
        /* this.$vs.loading();
        this.$http.get("/api/wms/v1/canvas/unloading/setting-value")
        .then((resp) => {
            if (resp.code == 200) {
                this.settingValue = resp.data
            }
            if (this.setting == "Auto") {
                this.tab = 2
            }
            this.render = false
            this.$nextTick(() => this.render = true)
            this.$vs.loading.close();
        })
        .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
        }); */
    },
    methods: {}
}
</script>